<template>
  <span>
    <Alert />
    <b-card class="h-100 shadow-sm" no-body>
      <b-card-header id="UsersFormHeader">
        <b-row class="mb-3">
          <b-col>
            <h3>{{ $t("views.users.title") }}</h3>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox
              v-model="Enable"
              name="check-button"
              switch
              id="UsersEnabledSwitch"
              >{{ $t("views.users.ShowUsersEnable") }}</b-form-checkbox
            >
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                  id="UsersSearchInput"
                />
                <b-button
                  v-if="keyword === ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  id="UsersSearchButton"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                  id="UsersDeleteSearch"
                >
                  <b-icon-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-checkbox
                  v-model="filtersShown"
                  name="filtersShown-button"
                  button
                  size="sm"
                  button-variant="primary"
                  class="ml-2"
                  v-b-popover.hover.top="{
                    customClass: 'blackPopup',
                    content: $t('general.filter'),
                  }"
                  id="UsersFilterButton"
                >
                  <b-icon icon="funnel" />
                </b-checkbox>
              </b-button-group>

              <b-button-group class="mx-1">
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.users.AddUser')"
                  :to="{ name: 'AddUser' }"
                  id="UsersAddUser"
                >
                  <b-icon-person-plus />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.users.EditUser')"
                  :disabled="!selectedUser"
                  @click="goToEdit"
                  id="UsersEditUser"
                >
                  <edit-person-icon />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.users.RemoveUser')"
                  :disabled="!selectedUser"
                  @click="remove"
                  id="UsersDeleteUser"
                >
                  <b-icon-person-x />
                </b-button>
                <b-button
                  v-show="!Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.users.ActivateUser')"
                  :disabled="!selectedUser"
                  @click="activate"
                  id="UsersActivateUser"
                >
                  <b-icon-person-check />
                </b-button>
                <b-button
                  v-show="!Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.users.RemovePermanentlyUser')"
                  :disabled="!selectedUser"
                  @click="validateCases"
                  id="UsersPermanentDeleteUser"
                >
                  <b-icon-person-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.users.ManageRoles')"
                  :disabled="!selectedUser"
                  :to="{
                    name: 'Roles',
                    params: { user: selectedUser },
                  }"
                  id="UsersManageRoles"
                >
                  <b-icon-person-badge />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  :title="$t('views.users.ChangePassword')"
                  size="sm"
                  v-b-modal.ChangePasswords
                  id="UsersChangePassword"
                >
                  <reset-password-icon />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-if="filtersShown">
              <b-form-select
                v-model="OUSelectedFilter"
                :options="OUFilter"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="overflow-auto p-0">
        <b-table
          :busy="busy"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          small
          outlined
          fixed
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
          id="UsersList"
        >
          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <simple-loader />
            </div>
          </template>
        </b-table>
      </b-card-body>

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="center"
        id="UsersPagination"
      ></b-pagination>
      <Confirm
        id="ChangePasswords"
        :title="$t('views.users.ChangePassword')"
        :message="$t('views.users.Message')"
        @onOk="onOk"
      />
      <Confirm
        id="DeletePermanently"
        :title="$t('views.users.DeletePermanentlyTitle')"
        :message="deletePermanentlyMessage"
        @onOk="deletePermanently"
      />
    </b-card>
  </span>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Confirm from "@/components/Confirm";
import Alert from "@/components/general/alert";
import ResetPassword from "@/components/general/icons/ResetPassword.vue";
import EditPerson from "@/components/general/icons/EditPerson.vue";

export default {
  components: {
    Confirm,
    Alert,
    "reset-password-icon": ResetPassword,
    "edit-person-icon": EditPerson,
  },
  watch: {
    UnitOrganitaion() {
      const that = this;
      this.busy = true;
      this.getUsers({
        api: this.$api,
        enabled: this.Enable,
        idOU: this.UnitOrganitaion,
      })
        .then(() => {
          that.busy = false;
        })
        .catch(() => {
          that.busy = false;
        });
    },
    Enable() {
      const that = this;
      this.busy = true;
      this.getUsers({
        api: this.$api,
        enabled: this.Enable,
      })
        .then(() => {
          that.busy = false;
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
    filtersShown(value) {
      if (!value) {
        this.OUSelectedFilter = null;
      }
    },
  },
  data() {
    return {
      OUSelectedFilter: null,
      filtersShown: false,
      busy: true,
      keyword: "",
      Edit: false,
      perPage: 10,
      currentPage: 1,
      Enable: true,
      isSelected: false,
      selected: [],
      deletePermanentlyMessage: null,
    };
  },
  created() {
    const that = this;
    this.busy = true;
    this.fetchOUs({
      session: this.$session,
      api: this.$api,
    });
    this.getUsers({
      api: this.$api,
      enabled: this.Enable,
      idOU: this.UnitOrganitaion,
    })
      .then(() => {
        that.busy = false;
      })
      .catch((e) => {
        console.log(e);
        that.busy = false;
      });
  },
  methods: {
    ...mapActions("Cases", ["fetchOUs"]),
    ...mapActions("User", [
      "getUsers",
      "enable",
      "disenable",
      "resetPassword",
      "deletePermanentlyUser",
      "getUserCases",
    ]),
    onOk() {
      this.resetPwd();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    remove() {
      const that = this;
      this.busy = true;
      (this.selected || []).forEach((element) => {
        that
          .disenable({
            api: that.$api,
            userName: element.userName,
          })
          .then((resp) => {
            this.busy = false;
            this.$bvToast.toast(resp.data.message, {
              variant: "success",
              title: this.$t("views.users.DeactivateUser"),
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch(() => {
            this.busy = false;
          });
      });
    },
    validateCases() {
      const that = this;
      (this.selected || []).forEach((element) => {
        that
          .getUserCases({
            api: that.$api,
            userName: element.userName,
          })
          .then((response) => {
            if (response.hasOpenCases) {
              that.deletePermanentlyMessage = that.$t(
                "views.users.DeletePermanentlyCases"
              );
            } else {
              that.deletePermanentlyMessage = that.$t(
                "views.users.DeletePermanentlyMessage"
              );
            }
            that.$bvModal.show("DeletePermanently");
          });
      });
    },
    deletePermanently() {
      const that = this;
      this.busy = true;
      (this.selected || []).forEach((element) => {
        that
          .deletePermanentlyUser({
            api: that.$api,
            userName: element.userName,
          })
          .then((resp) => {
            that.busy = false;
            that.$bvToast.toast(resp.data.message, {
              variant: "success",
              title: that.$t("views.users.DeactivateUser"),
              autoHideDelay: 5000,
              appendToast: true,
            });
            that.$bvModal.hide("DeletePermanently");
          })
          .catch(() => {
            that.busy = false;
          });
      });
    },
    activate() {
      const that = this;
      this.busy = true;
      (this.selected || []).forEach((element) => {
        that
          .enable({
            api: that.$api,
            userName: element.userName,
            idOU: that.UnitOrganitaion,
          })
          .then((resp) => {
            this.busy = false;
            this.$bvToast.toast(resp.data.message, {
              variant: "success",
              title: this.$t("views.users.ActivateUser"),
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch((error) => {
            this.busy = false;
            this.$bvToast.toast(error, {
              variant: "danger",
              title: this.$t("views.users.ActivateUser"),
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      });
    },
    resetPwd() {
      const that = this;
      this.busy = true;
      (this.selected || []).forEach((element) => {
        that
          .resetPassword({
            api: that.$api,
            Name: element.userName,
          })
          .then((resp) => {
            this.$bvModal.hide("ChangePasswords");
            this.busy = false;
            this.$bvToast.toast(resp.data.message, {
              variant: "success",
              title: this.$t("views.users.ResetingPassword"),
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch(() => {
            this.busy = false;
          });
      });
    },
    goToEdit() {
      const that = this;
      this.busy = true;
      (this.selected || []).forEach((element) => {
        that.$router.push({
          name: "User",
          params: { user: element.userName },
        });
      });
    },
  },
  computed: {
    ...mapGetters("OrganizationalUnit", ["UnitOrganitaion"]),
    ...mapGetters("Cases", ["organizationalUnits"]),
    ...mapGetters("User", ["users"]),
    rows() {
      return this.items.length;
    },
    items() {
      this?.users.forEach((user) => {
        if (this.OUSelectedFilter)
          user.role = user.lUserOURoles.find(
            (rol) => rol.idOrganizationalunit === this.OUSelectedFilter
          )
            ? user.lUserOURoles.find(
                (rol) => rol.idOrganizationalunit === this.OUSelectedFilter
              ).nameRole
            : this.$t("general.none");
        else {
          var highestRole = user.lUserOURoles.find((x) => x.idRole == 1);
          if (!highestRole) {
            highestRole = user.lUserOURoles.find((x) => x.idRole == 2);
            if (!highestRole) {
              highestRole = user.lUserOURoles.find((x) => x.idRole == 5);
              if (!highestRole) {
                highestRole = user.lUserOURoles.find((x) => x.idRole == 3);
                if (!highestRole) {
                  highestRole = user.lUserOURoles.find((x) => x.idRole == 4);
                }
              }
            }
          }
          user.role = highestRole
            ? highestRole.nameRole
            : this.$t("general.none");
        }
      });

      let byOUUsers = this.OUSelectedFilter
        ? this.users.filter((x) =>
            x.lUserOURoles.find(
              (rol) => rol.idOrganizationalunit === this.OUSelectedFilter
            )
          )
        : this.users;
      return this.keyword
        ? byOUUsers.filter(
            (item) =>
              item.mail.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.userName.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : byOUUsers;
    },
    selectedUser() {
      return this.selected.length ? this.selected[0].userName : "";
    },
    fields() {
      let fields = [
        {
          key: "userName",
          sortable: true,
          label: this.$t("views.users.fields.UserName"),
        },
        {
          key: "name",
          sortable: true,
          label: this.$t("views.users.fields.Name"),
        },
        {
          key: "mail",
          sortable: true,
          label: this.$t("views.users.fields.Mail"),
        },
        {
          key: "role",
          sortable: true,
          label: this.$t("views.users.fields.role"),
        },
      ];
      return fields;
    },
    OUFilter() {
      let OU = [
        { value: null, text: this.$t("views.home.OrganizationalUnits") },
      ];
      if (this.organizationalUnits.organizationalUnits) {
        this.organizationalUnits.organizationalUnits.forEach((element) => {
          OU.push({
            value: element.idOU,
            text: element.nameOrganizationalUnit,
          });
        });
      }
      return OU;
    },
  },
};
</script>

<style>
.blackPopup {
  color: #fff;
  background-color: #000;
}

.popover-body {
  color: #fff;
}

.arrow:after {
  border-top-color: #000;
}
</style>
